import React from 'react';

import {
    PluridApplication,
    PluridPlane,
} from '@plurid/plurid-react';

import Dashboard from './planes/Dashboard';



const App = () => {
    const pluridPlanes: PluridPlane[] = [
        {
            route: '/',
            component: {
                kind: 'react',
                element: Dashboard,
            },
        },
    ];

    const pluridView = ['/'];


    return (
        <PluridApplication
            configuration={{
                elements: {
                    plane: {
                        controls: {
                            show: false,
                        },
                    },
                },
            }}
            planes={pluridPlanes}
            view={pluridView}
        />
    );
}


export default App;
